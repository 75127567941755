import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TimelineComponent } from 'src/app/components/timeline/timeline.component';
import {
  Chapter,
  RoleData,
  TimeLine,
  TimeLineDetails,
} from 'src/app/page/project-edit/project-edit.component';
import { environment } from '../../../environments/environment';
import { BibleBooksService } from '../bible-book/bible-book.service';
import { DialogsService } from '../dialog/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectProfileService implements OnInit {
  timeLineResponse: TimeLine[] | undefined;
  timeLineDetails: any = [];
  timeLineContent: TimeLineDetails[] | undefined;
  timeLineData: RoleData | undefined;

  mttTimelineData: RoleData | undefined;
  faciTimelineData: RoleData | undefined;
  citTimelineData: RoleData | undefined;
  transTimelinedata: RoleData | undefined;
  corTimelineData: RoleData | undefined;
  conTimelineData: RoleData | undefined;
  techTimelineData: RoleData | undefined;
  showTimeLineData: any;
  roleStageName = [
    { role: 'MTT', stageName: ['Draft', 'Cross Check', 'Master Record'] },
    { role: 'Facilitator', stageName: ['Community Check','Back Translation'] },
    { role: 'CIT', stageName: ['CIT Check'] },
    { role: 'Consultant', stageName: ['Consultant Check'] },
    { role: 'Technician', stageName: ['Publishing'] },
    {
      role: 'Coordinator',
      stageName: ['Stage 1'],
    },
    {
      role: 'Translation Manager',
      stageName: ['Stage 1'],
    },
  ];
  storyList: Array<string> = [
    'Birth of Jesus',
    'Life of Jesus',
    'Crucifiction',
  ].sort();
  stage: any = [];
  isJesusFilm: boolean | undefined;

  ngOnInit() {}

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private dialogService: DialogsService,
    private bibleBooksService: BibleBooksService
  ) {
    this.showTimeLineData = [];
  }

  createProject(data: any): Observable<HttpEvent<{}>> {
    const req = new HttpRequest(
      'POST',
      `${environment.services.rhemaServer}/project/createProject`,
      data,
      {
        reportProgress: true,
        responseType: 'text',
      }
    );
    return this.http.request(req);
  }

  getProjects(): Observable<any> {
    return this.http.get(
      `${environment.services.rhemaServer}/project/getProjects`
    );
  }

  getCoordinatorProjects(): Observable<any> {
    const token = localStorage.getItem('token');
    return this.http.get(
      `${environment.services.rhemaServer}/project/getProjectsByLanguage?token=${token}`
    );
  }

  getProjectById(id: any): Observable<any> {
    return this.http.get(
      `${environment.services.rhemaServer}/project/getProject/${id}`
    );
  }

  getProjectsByCountry(country: string): Observable<any> {
    return this.http.post(
      `${environment.services.rhemaServer}/project/getProjectsByCountry`,
      {country},
      
    );
  }
  
  getTimeLine(id: any): Observable<any> {
    return this.http.get(
      `${environment.services.rhemaServer}/timeLine/getTimeLine/${id}`
    );
  }

  updateProjectById(id: any, data: any): Observable<HttpEvent<{}>> {
    const req = new HttpRequest(
      'PUT',
      `${environment.services.rhemaServer}/project/updateProjectById/${id}`,
      data,
      {
        reportProgress: true,
        responseType: 'text',
      }
    );
    return this.http.request(req);
  }


  async isJesusFilmProject(isJesusFilm: string){
    this.isJesusFilm= isJesusFilm=='Jesus Film' ? true: false;
  }

  async getJesusFilmDetail(){
    this.timeLineDetails = [];    
    this.stage[0].stageName.forEach((stage: any)=>{
      const timeLineObject: TimeLineDetails = {
        bookName: 'Jesus Film',
        stageName: stage,
        chapters: this.storyList.map((chapterName) => {
          return {
            chapterName,
            noOfDays: 0,
          } as Chapter;
        }),
        noOfDays: 0,
      };
      this.timeLineDetails?.push([timeLineObject]);
      return timeLineObject;
    });
  }

  async getBookName() {
    this.timeLineDetails = [];
    this.bibleBooksService.getBooks().subscribe((response) => {
      let obj = { ...response['New testament'], ...response['Old testament'] };
      this.stage[0].stageName.forEach((stage: any) => {
        this.timeLineContent = Object.keys(obj).map((element: any) => {
          const timeLineObject: TimeLineDetails = {
            bookName: element,
            stageName: stage,
            chapters: Object.keys(obj[element]).map((chapterName) => {
              return {
                chapterName,
                noOfDays: 0,
              } as Chapter;
            }),
            noOfDays: 0,
          };
          return timeLineObject;
        });        
        this.timeLineDetails?.push([...this.timeLineContent]);
      });
    });
  }

  async showTimeline(userId: any, projectId: any, role: string) {
    this.stage = this.roleStageName.filter(
      (stageFilter: any) => stageFilter.role == role
    );
    this.dialogService.confirm();
    this.isJesusFilm ==true ? await this.getJesusFilmDetail() : await this.getBookName() 
    await this.wait();
    this.getTimeLine(projectId).subscribe(async (response) => {
      this.timeLineResponse = await response;
      await this.wait();
      let timeLineCheck = await this.timeLineResponse?.find(
        (timeData: TimeLine) => timeData.userId === userId
      );
      await this.dialog
        .open(TimelineComponent, {
          data:
            timeLineCheck != undefined
              ? timeLineCheck
              : ({
                  userId,
                  role,
                  projectId: projectId,
                  timeLineDetails: this.timeLineDetails,
                } as TimeLine),
        })
        .afterClosed()
        .subscribe(async (response) => {
          this.timeLineData = {
            projectId: '',
            userId: '',
            role: '',
            timeLineDetails: [],
          };
          //   if(this.showTimeLineData.length==0){
          //     this.timeLineData = {
          //       projectId: response.projectId,
          //       userId: response.userId,
          //       role: response.role,
          //       timeLineDetails: response.userArr,
          //     };
          //     this.showTimeLineData[0]=this.timeLineData;
          //     console.log("show time index 0");
          // }else{
          //   for(let i=0;i<this.showTimeLineData.length;i++){
          //     const index = this.showTimeLineData.findIndex(
          //       (showTime: TimeLine) => showTime.userId === userId
          //     );
          //     if (index > -1) {
          //       console.log("exists");

          //       this.showTimeLineData[index] = this.timeLineData;
          //     } else {
          //       console.log("new one");

          //       this.showTimeLineData.push(this.timeLineData);
          //     }
          //   }
          // }
          switch (response.role) {
            case 'MTT':
              this.mttTimelineData = {
                projectId: response.projectId,
                userId: response.userId,
                role: response.role,
                timeLineDetails: response.userArr,
              };
              const index = this.showTimeLineData.findIndex(
                (showTime: TimeLine) => showTime.userId === userId
              );
              if (index > -1) {
                this.showTimeLineData[index] = this.mttTimelineData;
              } else {
                this.showTimeLineData.push(this.mttTimelineData);
              }              
              break;
            case 'Facilitator':
              this.faciTimelineData = {
                projectId: response.projectId,
                userId: response.userId,
                role: response.role,
                timeLineDetails: response.userArr,
              };
              const index_1 = this.showTimeLineData.findIndex(
                (showTime: TimeLine) => showTime.userId === userId
              );
              if (index_1 > -1) {
                this.showTimeLineData[index_1] = this.faciTimelineData;
              } else {
                this.showTimeLineData.push(this.faciTimelineData);
              }
              break;

            case 'CIT':
              this.citTimelineData = {
                projectId: response.projectId,
                userId: response.userId,
                role: response.role,
                timeLineDetails: response.userArr,
              };
              const index_2 = this.showTimeLineData.findIndex(
                (showTime: TimeLine) => showTime.userId === userId
              );
              if (index_2 > -1) {
                this.showTimeLineData[index_2] = this.citTimelineData;
              } else {
                this.showTimeLineData.push(this.citTimelineData);
              }
              break;

            case 'Consultant':
              this.conTimelineData = {
                projectId: response.projectId,
                userId: response.userId,
                role: response.role,
                timeLineDetails: response.userArr,
              };
              const index_3 = this.showTimeLineData.findIndex(
                (showTime: TimeLine) => showTime.userId === userId
              );
              if (index_3 > -1) {
                this.showTimeLineData[index_3] = this.conTimelineData;
              } else {
                this.showTimeLineData.push(this.conTimelineData);
              }
              break;

            case 'Technician':
              this.techTimelineData = {
                projectId: response.projectId,
                userId: response.userId,
                role: response.role,
                timeLineDetails: response.userArr,
              };
              const index_4 = this.showTimeLineData.findIndex(
                (showTime: TimeLine) => showTime.userId === userId
              );
              if (index_4 > -1) {
                this.showTimeLineData[index_4] = this.techTimelineData;
              } else {
                this.showTimeLineData.push(this.techTimelineData);
              }
              break;

            case 'Coordinator':
              this.corTimelineData = {
                projectId: response.projectId,
                userId: response.userId,
                role: response.role,
                timeLineDetails: response.userArr,
              };
              const index_5 = this.showTimeLineData.findIndex(
                (showTime: TimeLine) => showTime.userId === userId
              );
              if (index_5 > -1) {
                this.showTimeLineData[index_5] = this.corTimelineData;
              } else {
                this.showTimeLineData.push(this.corTimelineData);
              }
              break;

            case 'Translate Manager':
              this.transTimelinedata = {
                projectId: response.projectId,
                userId: response.userId,
                role: response.role,
                timeLineDetails: response.userArr,
              };
              const index_6 = this.showTimeLineData.findIndex(
                (showTime: TimeLine) => showTime.userId === userId
              );
              if (index_6 > -1) {
                this.showTimeLineData[index_6] = this.transTimelinedata;
              } else {
                this.showTimeLineData.push(this.transTimelinedata);
              }
              break;
          }
          this.dialogService.closeModal();
        });
    });
    
  }

  validateUserDuplication(
    consultants?: Array<any>,
    translators?: Array<any>,
    facilitators?: Array<any>,
    cits?: Array<any>,
    technicians?: Array<any>,
    coordinators?: Array<any>,
    translateManagers?: Array<any>
  ): Array<any> {
    const dupConsultant: { email: any; role: string }[] = [];
    const dupTranslators: { email: any; role: string }[] = [];
    const dupFacilitator: { email: any; role: string }[] = [];
    const dupCits: { email: any; role: string }[] = [];
    const dupTechnicians: { email: any; role: string }[] = [];
    const dupCoordinators: { email: any; role: string }[] = [];
    const duptranslateManagers: { email: any; role: string }[] = [];
    const dupMajorLanguage = [];

    consultants
      ?.map((elem) => elem.consultant.email)
      .forEach((email, index, arr) => {
        if (arr.indexOf(email) !== index) {
          dupConsultant.push({ email: email, role: 'Consultant' });
        }
      });
    translators
      ?.map((elem) => elem.mtt.email)
      .forEach((email, index, arr) => {
        if (arr.indexOf(email) !== index) {
          dupTranslators.push({ email: email, role: 'MTT' });
        }
      });
    facilitators
      ?.map((elem) => elem.facilitator.email)
      .forEach((email, index, arr) => {
        if (arr.indexOf(email) !== index) {
          dupFacilitator.push({ email: email, role: 'Facilitator' });
        }
      });
    cits
      ?.map((elem) => elem.cit.email)
      .forEach((email, index, arr) => {
        if (arr.indexOf(email) !== index) {
          dupCits.push({ email: email, role: 'CIT' });
        }
      });
    technicians
      ?.map((elem) => elem.technician.email)
      .forEach((email, index, arr) => {
        if (arr.indexOf(email) !== index) {
          dupTechnicians.push({ email: email, role: 'Technician' });
        }
      });
    coordinators
      ?.map((elem) => elem.coordinator.email)
      .forEach((email, index, arr) => {
        if (arr.indexOf(email) !== index) {
          dupCoordinators.push({ email: email, role: 'Coordinator' });
        }
      });
    translateManagers
      ?.map((elem) => elem.translateManager.email)
      .forEach((email, index, arr) => {
        if (arr.indexOf(email) !== index) {
          duptranslateManagers.push({
            email: email,
            role: 'Translation Manager',
          });
        }
      });
    return [
      ...dupConsultant,
      ...dupTranslators,
      ...dupFacilitator,
      ...dupCits,
      ...dupTechnicians,
      ...dupCoordinators,
      ...duptranslateManagers,
    ];
  }

  getProjectsByRole(user: any): Observable<any> {
    return this.http.post(
      `${environment.services.rhemaServer}/project/getProjectsByrole`,
      user
    );
  }

  getTimeLineInfo(): Observable<any> {
    return this.showTimeLineData;
  }

  async wait() {
    return new Promise(async (resolve, reject) => {
      setTimeout(() => {
        resolve('wait');
      }, 0);
    });
  }
}
