<mat-toolbar color="secondary" class="header-logo">
    <mat-toolbar-row>
        <span color="primary" class="img"><img src="./assets/images/logo.png" height="60"
                class="cursor-pointer logo-img" (click)="redirectToHome()" /></span>
        <span class="remaining-space"></span>
        <!-- <button *ngIf="(adminLoggedIn && currentRole === 'Coordinator')" (click)="logOut()"
            class="header-logout">LogOut
        </button> -->
    </mat-toolbar-row>
</mat-toolbar>

<mat-card class="mat-card sign-up">
    <mat-card-header class="background-primary font-primary border-top-5">
        <mat-card-title>Log-In</mat-card-title>
    </mat-card-header>
    <form>
        <div *ngIf="isDisplay">
            <app-notification [message]="message"> </app-notification>

        </div>
        <mat-card-content class="adminForm">
            <!-- <mat-form-field color="accent">
                <input matInput placeholder="Email" formControlName="email" autofocus>
                <mat-error *ngIf="adminDetails.controls.email.errors?.required">Please enter a valid email</mat-error>  
            </mat-form-field>
            <mat-form-field color="accent">
                <input type="password" matInput placeholder="Password" formControlName="password" autofocus>
               <mat-error *ngIf="adminDetails.controls.password.errors?.required">Please enter a valid password  
                </mat-error>
            </mat-form-field>
            <div class="button-row">
                <button [ngClass]="{btn: adminDetails.valid}" [disabled]="!adminDetails.valid" mat-raised-button
                    class="mr-3" (click)="adminLogin()">Login
                </button>

            </div> -->
            <p>
                <mat-form-field appearance="legacy">

                    <mat-icon matPrefix>
                        <span class="material-symbols-outlined">
                            mail
                        </span>
                    </mat-icon>
                    <mat-label class="mx-2">Email</mat-label>
                    <input matInput class="mx-2" name="email" [(ngModel)]="email" />
                </mat-form-field>



                <mat-form-field appearance="legacy">
                    <mat-icon matPrefix (click)="hide = !hide" class="visible">
                        {{hide ? ' lock ': ' lock_open ' }}
                    </mat-icon>
                    <mat-label class="mx-2">Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" name="password" [(ngModel)]="password" />

                </mat-form-field>

            </p>
            <div class="button-row ">
                <!-- [ngClass]="{btn: adminDetails.valid}" [disabled]="!adminDetails.valid"  (click)="adminLogin()" -->
                <button mat-raised-button type="button" class="mr-3  btn-primary rounded-sm text-white flex"
                    (click)="onSubmit()">
                    <span *ngIf="isLoading">
                        <app-spinner></app-spinner>
                    </span>
                    Login
                </button>

            </div>
        </mat-card-content>
    </form>
</mat-card>