<header>
    <mat-toolbar color="secondary" class="header-logo">
        <mat-toolbar-row>
            <span color="primary" class="img">
                <img src="./assets/images/logo.png" height="60"
                    class="cursor-pointer logo-img" (click)="redirectToHome()" /></span>
            <span class="remaining-space"></span>
            <!-- <button *ngIf="(adminLoggedIn && currentRole === 'Coordinator')" (click)="logOut()"
                class="header-logout">LogOut
            </button> -->
        </mat-toolbar-row>
    </mat-toolbar>
</header>

<mat-card class="hero-top">
    <div class="hero-section">
        <div class="hero-content">
            <h3>Welcome to Rhema App</h3>
            <p>Rhema brings a unique Android based application dedicated for one mission to help
                translate Bible from any available source. </p>
            <div class="button-row">
                <!-- <button mat-raised-button color="primary">Read More</button> -->
                <button mat-raised-button color="primary" class="adminLogin" (click)="adminLogin()">Login</button>
            </div>
        </div>
        <div class="hero-img"></div>
    </div>
</mat-card>
<mat-card class="footer-top">
    <div class="install-section">
        <div>
            <div class="install-text">
                <h2>Rhema App is Awesome !<br />
                    It’s Free to Upload Audio Files</h2>
            </div>
            <div class="install-img">
                <div class="android-button cursor-pointer" (click)="playStore()">
                    <!-- <mat-icon class="android-icon">android</mat-icon> -->
                    <svg stroke="white" fill="white" stroke-width="0" viewBox="0 0 1024 1024" height="25" width="25" xmlns="http://www.w3.org/2000/svg"><path d="M270.1 741.7c0 23.4 19.1 42.5 42.6 42.5h48.7v120.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V784.1h85v120.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V784.1h48.7c23.5 0 42.6-19.1 42.6-42.5V346.4h-486v395.3zm357.1-600.1l44.9-65c2.6-3.8 2-8.9-1.5-11.4-3.5-2.4-8.5-1.2-11.1 2.6l-46.6 67.6c-30.7-12.1-64.9-18.8-100.8-18.8-35.9 0-70.1 6.7-100.8 18.8l-46.6-67.5c-2.6-3.8-7.6-5.1-11.1-2.6-3.5 2.4-4.1 7.4-1.5 11.4l44.9 65c-71.4 33.2-121.4 96.1-127.8 169.6h486c-6.6-73.6-56.7-136.5-128-169.7zM409.5 244.1a26.9 26.9 0 1 1 26.9-26.9 26.97 26.97 0 0 1-26.9 26.9zm208.4 0a26.9 26.9 0 1 1 26.9-26.9 26.97 26.97 0 0 1-26.9 26.9zm223.4 100.7c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V400.1c.1-30.6-24.3-55.3-54.6-55.3zm-658.6 0c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V400.1c0-30.6-24.5-55.3-54.6-55.3z"></path></svg>
                    <p>Available on play Store</p>
                </div>
                <!--<div class="ios-button">
                    <mat-icon>phone_iphone</mat-icon>
                    <p class="dsp-tc">available on <br> <span>Apple Store</span></p>
                </div>-->
    
                <!--<mat-icon>android</mat-icon>-->
                <!--<mat-icon>phone_iphone</mat-icon>-->
            </div>
        </div>
        <div>
            <div class="install-text">
                <h2>Download on Rhema Desk App</h2>
            </div>
            <div class="dbl">
                <div class="install-img">
                    <div class="android-button cursor-pointer" (click)="onWindowsDownload()">
                        <svg stroke="white" fill="white" stroke-width="0" viewBox="0 0 1024 1024" height="25" width="25" xmlns="http://www.w3.org/2000/svg"><path d="M523.8 191.4v288.9h382V128.1zm0 642.2l382 62.2v-352h-382zM120.1 480.2H443V201.9l-322.9 53.5zm0 290.4L443 823.2V543.8H120.1z"></path></svg>
                        <p>Available for Windows OS</p>
                    </div>
                </div>
        
                <div class="install-img">
                    <div class="android-button cursor-pointer" (click)="onMacDownload()">
                        <svg stroke="white" fill="white" stroke-width="0" viewBox="0 0 16 16" height="25" width="25" xmlns="http://www.w3.org/2000/svg"><path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z"></path><path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z"></path></svg>
                        <p>Available on Mac OS</p>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  
</mat-card>

<!--<mat-card>-->
<!--<mat-card-title>-->
<!--Welcome-->
<!--</mat-card-title>-->

<!--<div class="button-row">-->
<!--<button mat-raised-button color="primary" (click)="createUser()">Create User</button>-->
<!--<button mat-raised-button color="primary" (click)="uploadAudioFiles()">Upload AudioFiles</button>-->
<!--</div>-->
<!--</mat-card>-->